import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import PrintForm from './PrintForm';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const UpdateForm = () => {
  const componentRef = useRef();
  const [editopen, setEditOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [whatsappdata, setWhatsappdata] = useState(null);
  const [isText, setIsText] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    employee_name: '',
    employee_code: '',
    section: '',
    out_time: '',
    in_time: '',
    employee_mobile_no: '',
    reason: '',
    whatsapp_no: '',
    photo_path: '',
    image: '',
    response: '',
    response_reason: '',
  });
  const { id } = useParams();
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL + 'gatepass/' + id);
      setFormData(response.data[0]);
      setSelectedValue(response.data[0]['whatsapp_no']);
      console.log('formdata ', response.data[0]);
      setSelectedOption(response.data[0]['response']);


    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleReset = (e) => {
    setFormData({
      employee_name: '',
      employee_code: '',
      section: '',
      out_time: '',
      in_time: '',
      employee_mobile_no: '',
      reason: '',
      whatsapp_no: '',
      photo_path: '',
      image: '',
      response: '',
      response_reason: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(baseURL + 'gatepass/' + id, formData);
      // setOpen(true);
      console.log('Form data posted successfully:', response.data);
      gotowhatsapp(id);
    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };


  function gotowhatsapp(id) {
    var employee_name = formData.employee_name;
    var employee_code = formData.employee_code;
    var section = formData.section;
    var out_time = formData.out_time;
    var in_time = formData.in_time;
    var employee_mobile_no = formData.employee_mobile_no;
    var response = formData.response;
    var response_reason = formData.response_reason;
    var whatsapp_no = selectedValue;
    var resUrl = "https://gatepass.deeps.blog/%23/workmen/" + id;

    var url =
      "https://wa.me/91" +
      whatsapp_no +
      "?text=" +
      "Employee Name: " +
      employee_name +
      "%0a" +
      "Company Name: " +
      employee_code +
      "%0a" +
      "Company Address: " +
      section +
      "%0a" +
      "Visitor Mobile: " +
      out_time +
      "%0a" +
      "To Meet Whom: " +
      in_time +
      "%0a" +
      "Employee Mobile No: " +
      employee_mobile_no +
      "%0a" +
      "Response: " +
      response +
      "%0a" +
      "Response Reason: " +
      response_reason +
      "%0a" +
      "Response Link: " +
      resUrl;

    console.log('formdata ', formData);
    console.log('formdata ', selectedValue);
    console.log('formdata ', url);

    window.open(url, "_blank").focus();
  }

  useEffect(() => {
    fetchWhatsApp();
  }, []);

  const fetchWhatsApp = async () => {
    try {
      const response = await axios.get(baseURL + 'whatsapp', {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending blobs
        },
      });
      console.log('setWhatsappdata', response.data);
      setWhatsappdata(response.data);

    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };

  function checkDate(data) {
    const date = new Date(data);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // timeZoneName: 'short'
    };

    const humanReadableDate = date.toLocaleString('en-US', options);
    return humanReadableDate;
  }



  const handleWhatsAppChange = (event) => {
    setSelectedValue(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      reponse_whatsapp_no: event.target.value,
    }));
  };

  const changeToText = () => {
    setIsText(!isText);
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value == "Yes") {
      setFormData((prevData) => ({
        ...prevData,
        response: "Yes",
        response_reason: "Allowed",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        response: "No",
        response_reason: "Not Allowed",
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      response: event.target.value,
    }));
  };


  return (
    <>
      <Modal
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Box sx={style} ref={componentRef}>
          <PrintForm
            onClick={handleEditClose}
            visitorData={formData}
          />
        </Box>
      </Modal>
      <div className="w-full max-w-md mx-auto my-10">
        <div className="flex items-center justify-center mb-2">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Logo"
            className="h-16"
          />
          <div>
            <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
            <h1 className="register-heading ml-4 text-lg">WORKMEN GATE PASS </h1>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          {/* Form fields */}
          <div>
            <FormControl variant="outlined" sx={{ minWidth: "100%" }}>
              <Typography variant="subtitle1" className="block text-gray-700 text-sm font-bold mb-2" gutterBottom>
                Response
              </Typography>
              <Select
                value={selectedOption}
                onChange={handleOptionChange}
                label="Dropdown"
                sx={{ height: '36px' }}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="response_reason">
              Response Reason
            </label>
            <input
              type="text"
              name="response_reason"
              value={formData.response_reason}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Response Reason"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_name">
              Employee Name
            </label>
            <input
              type="text"
              name="employee_name"
              disabled
              value={formData.employee_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Name"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Employee Code
            </label>
            <input
              type="text"
              name="employee_code"
              disabled
              value={formData.employee_code}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Code"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Section
            </label>
            <input
              type="text"
              name="section"
              value={formData.section}
              disabled
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Section"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Out Time
            </label>
            <input
              type="time"
              name="out_time"
              value={formData.out_time}
              disabled
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Out Time"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              In Time
            </label>
            <input
              type="time"
              name="in_time"
              value={formData.in_time}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="In Time"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_mobile_no">
              Employee Mobile No
            </label>
            <input
              type="text"
              name="employee_mobile_no"
              value={formData.employee_mobile_no}
              onChange={handleChange}
              disabled
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Mobile No"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
              Reason
            </label>
            <input
              type="text"
              name="reason"
              value={formData.reason}
              disabled
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Reason"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <div className="flex justify-between">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="whatsapp_no">
                WhatsApp Number
              </label>
              {!isText ? (
                <button
                  type="button"
                  onClick={() => changeToText()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-semibold px-3 mb-2 rounded-md focus:outline-none focus:shadow-outline"
                >
                  Add Text
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => changeToText()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-semibold px-3 mb-2 rounded-md focus:outline-none focus:shadow-outline"
                >
                  Select
                </button>
              )}

            </div>
            {isText ? (
              <>
                <input
                  type="text"
                  value={selectedValue}
                  onChange={handleWhatsAppChange}
                  className="w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Enter WhatsApp Number"
                />
              </>
            ) : (
              <>
                <select
                  value={selectedValue}
                  onChange={handleWhatsAppChange}
                  className="appearance-none block w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value=""></option>
                  {whatsappdata && whatsappdata.map((record, index) => (
                    <option key={index} value={record.number}>
                      {record.number} - {record.name} 
                    </option>
                  ))}
                </select>
              </>
            )}


          </div >

          {/* Submit button */}
          <div className="flex items-center justify-center">
            <div
              onClick={handleEditOpen}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Print
            </div>
            <div
              onClick={handleReset}
              className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Reset
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Submit
            </button>
          </div>
        </form >
      </div >
    </>
  );
};

export default UpdateForm;
