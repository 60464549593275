import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import axios from "axios";

export default function EditForm({ fid, closeEvent }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log("FID: " + fid.id);
    setName(fid.name);
    setDescription(fid.description);
  }, []);

  const createUser = async (url) => {
    let posturl = baseURL + "symptom/" + fid.id;

    await axios
      .put(
        posturl,
        {
          name: name,
          description: description,
        },
      )
      .then(function (res) {
        console.log(res.data);
        getUsers();
        closeEvent();
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "symptom";
    await axios
      .get(posturl,
      )
      .then((res) => {
        // updateCategories(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };


  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Edit Symptom
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={false}
            id="name"
            name="name"
            value={name}
            onChange={handleNameChange}
            label="Name"
            size="small"
            sx={{ marginTop: "30px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            id="description"
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            label="Description"
            size="small"
            sx={{ marginTop: "0px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
