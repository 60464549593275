import React, { useState, useEffect } from 'react';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import EditIcon from '@mui/icons-material/Edit';
import { CSVLink, CSVDownload } from "react-csv";

function Button(props) {
  return (
    <button
      className={`px-4 py-2 ${props.color} ${props.size} rounded-md`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}

export default function DataTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [visitorData, setVisitorData] = useState(null);
  const [visitorDataAll, setVisitorDataAll] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredVisitors, setFilteredVisitors] = useState([]);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const csvHeaders = [
    { label: 'Name' },
    { label: 'Email' },
  ];

  function checkDate(data) {
    const date = new Date(data);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      // timeZoneName: 'short'
    };

    const humanReadableDate = date.toLocaleString('en-US', options);
    return humanReadableDate;
  }


  // useEffect(() => {
  //   if (visitorData) {
  //     let fVisitors = visitorData.filter((visitor) =>
  //       visitor.visitor_name.toLowerCase().includes(searchQuery.toLowerCase())
  //     );
  //     setFilteredVisitors(fVisitors);
  //     setLoading(true);
  //   }
  // }, [visitorData]);

  useEffect(() => {
    searchData(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    fetchData();
  }, []);

  const searchData = (searchQuery) => {
    let filteredData = visitorData;
    if (searchQuery) {
      filteredData = visitorData.filter((visitor) =>
        visitor.employee_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.employee_mobile_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.whatsapp_no.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.out_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.in_time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.reason.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.employee_code.toLowerCase().includes(searchQuery.toLowerCase()) ||
        visitor.section.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setVisitorData(filteredData);
    } else {
      setVisitorData(visitorDataAll);
    }
  }

  const fetchData = async () => {
    try {
      const response = await axios.get(baseURL + 'gatepass');
      // Filter the data based on the searchQuery
      setVisitorData(response.data);
      setVisitorDataAll(response.data);
      console.log(response.data);
      setLoading(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };



  const editRecord = (event) => {
    var resUrl = "https://gatepass.deeps.blog/#/workmen/" + event;
    const newTab = window.open(resUrl, '_blank');
    newTab.focus();
  };

  const deleteRecord = (event) => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to deletethis record?.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => deleteApi(event)
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const deleteApi = async (event) => {
    // starting
    await axios
      .delete(baseURL + "gatepass/" + event, {})
      .then(function (response) {
        fetchData();
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
    // ending
  };

  return (
    <>
      {loading && (
        <div className="mx-32 my-10">
          <div className="flex items-center justify-center mb-2">
            {/* Logo */}
            <img
              src="/logo.png"
              alt="Logo"
              className="h-16"
            />
            <div>
              <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
              <h1 className="register-heading ml-4 text-lg">WORKMEN GATE PASS </h1>
            </div>
          </div>
          <div className='flex justify-between'>
            <div>
              <input
                type="text"
                placeholder="Search by visitor name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mb-2 px-2 py-1 border rounded"
              />
            </div>
            <CSVLink
              data={visitorData}
              // headers={csvHeaders}
              filename={'exported_data.csv'}
              className="btn btn-primary bg-green-500 p-2 m-2 text-white border-10 rounded-md"
            >
              Export CSV
            </CSVLink>
          </div>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" stripe="odd">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      ID
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Employee Code
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Section
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Out Time
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      In Time
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Employee Mobile No
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Reason
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      WhatsApp No
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Response WhatsApp No
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Response
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Response Reason
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Created At
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visitorData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell align="left">{row.id}</TableCell>
                          <TableCell align="left">{row.employee_name}</TableCell>
                          <TableCell align="left">{row.employee_code}</TableCell>
                          <TableCell align="left">{row.section}</TableCell>
                          <TableCell align="left">{row.out_time}</TableCell>
                          <TableCell align="left">{row.in_time}</TableCell>
                          <TableCell align="left">{row.employee_mobile_no}</TableCell>
                          <TableCell align="left">{row.reason}</TableCell>
                          <TableCell align="left">{row.whatsapp_no}</TableCell>
                          <TableCell align="left">{row.reponse_whatsapp_no}</TableCell>
                          <TableCell align="left">{row.response}</TableCell>
                          <TableCell align="left">{row.response_reason}</TableCell>
                          <TableCell align="left">{checkDate(row.created_at)}</TableCell>
                          <TableCell style={{ width: 20 }}>
                            <div className="flex">
                              <Button color="bg-blue-500 text-white" size="text-sm" onClick={() => editRecord(row.id)}>
                                Edit
                              </Button>
                              <Button color="bg-red-500 text-white ml-2" size="text-sm" onClick={() => deleteRecord(row.id)}>
                                Delete
                              </Button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={visitorData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      )}

    </>
  );
}
