import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import PrintForm from './PrintForm';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from '@mui/material/Divider';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const VisitorForm = () => {
  const componentRef = useRef();
  const [editopen, setEditOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [whatsappdata, setWhatsappdata] = useState(null);
  const [empdata, setEmpdata] = useState(null);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const [image, setImage] = useState(null);
  const hiddenFileInput = useRef(null);
  const baseURL = process.env.REACT_APP_API_URL;
  const [isText, setIsText] = useState(false);
  const [rowdata, setRowdata] = useState([]);
  const [rownamedata, setRowNamedata] = useState([]);
  const [formData, setFormData] = useState({
    employee_name: '',
    employee_code: '',
    section: '',
    out_time: '',
    in_time: '',
    employee_mobile_no: '',
    reason: '',
    whatsapp_no: '',
    photo_path: '',
    image: '',
    response: '',
    response_reason: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = (e) => {
    setFormData({
      employee_name: '',
      employee_code: '',
      section: '',
      out_time: '',
      in_time: '',
      employee_mobile_no: '',
      reason: '',
      whatsapp_no: '',
      photo_path: '',
      image: '',
      response: '',
      response_reason: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(baseURL + 'gatepass', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending blobs
        },
      });
      console.log('Form data posted successfully:', response.data.created_id);
      gotowhatsapp(response.data.created_id);
      // Reset form data after successful submission
      setFormData({
        employee_name: '',
        employee_code: '',
        section: '',
        out_time: '',
        in_time: '',
        employee_mobile_no: '',
        reason: '',
        whatsapp_no: '',
        photo_path: '',
        response: '',
        details: '',
        response_reason: '',
      });
    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };

  function gotowhatsapp(id) {
    var employee_name = formData.employee_name;
    var employee_code = formData.employee_code;
    var section = formData.section;
    var out_time = formData.out_time;
    var in_time = formData.in_time;
    var employee_mobile_no = formData.employee_mobile_no;
    var reason = formData.reason;
    var whatsapp_no = formData.whatsapp_no;
    var resUrl = "https://gatepass.deeps.blog/%23/workmen/" + id;

    var url =
      "https://wa.me/91" +
      whatsapp_no +
      "?text=" +
      "Employee Name: " +
      employee_name +
      "%0a" +
      "Company Name: " +
      employee_code +
      "%0a" +
      "Company Address: " +
      section +
      "%0a" +
      "Visitor Mobile: " +
      out_time +
      "%0a" +
      "To Meet Whom: " +
      in_time +
      "%0a" +
      "employee_mobile_no: " +
      employee_mobile_no +
      "%0a" +
      "reason: " +
      reason +
      "%0a" +
      "Response Link: " +
      resUrl;

    window.open(url, "_blank").focus();
  }

  useEffect(() => {
    fetchWhatsApp();
    fetchEmpData();
  }, []);

  const fetchEmpData = async () => {
    try {
      const response = await axios.get(baseURL + 'employee', {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending blobs
        },
      });
      // console.log('setEmpdata', response.data);
      setEmpdata(response.data);

    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };
  const fetchWhatsApp = async () => {
    try {
      const response = await axios.get(baseURL + 'whatsapp', {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for sending blobs
        },
      });
      // console.log('setWhatsappdata', response.data);
      setWhatsappdata(response.data);

    } catch (error) {
      console.error('Error posting form data:', error);
    }
  };

  const handleWhatsAppChange = (event) => {
    setSelectedValue(event.target.value);
    setFormData((prevData) => ({
      ...prevData,
      whatsapp_no: event.target.value,
    }));
  };


  const changeToText = () => {
    setIsText(!isText);
  }

  useEffect(() => {
    if (rowdata) {
      console.log('rowdata ', rowdata);
      setFormData((prevData) => ({
        ...prevData,
        employee_code: rowdata.emp_code,
        employee_name: rowdata.emp_name,
        employee_mobile_no: rowdata.mobile_no,
        section: rowdata.department,
      }));
    }
  }, [rowdata]);

  useEffect(() => {
    if (rownamedata) {
      console.log('rownamedata ', rownamedata);
      setFormData((prevData) => ({
        ...prevData,
        employee_code: rownamedata.emp_code,
        employee_name: rownamedata.emp_name,
        employee_mobile_no: rownamedata.mobile_no,
        section: rownamedata.department,
      }));
    }
  }, [rownamedata]);

  return (
    <>
      <Modal
        open={editopen}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflow: "auto" }}
      >
        <Box sx={style} ref={componentRef}>
          <PrintForm
            onClick={handleEditClose}
            visitorData={formData}
          />
        </Box>
      </Modal>

      <div className="w-full max-w-md mx-auto my-10">
        <div className="flex items-center justify-center mb-2">
          {/* Logo */}
          <img
            src="/logo.png"
            alt="Logo"
            className="h-16"
          />
          <div>
            <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
            <h1 className="register-heading ml-4 text-lg">WORKMEN GATE PASS </h1>
          </div>
        </div>
        <div className="bg-slate-50 px-5 py-5 border-2 mb-2 rounded-lg">
          <h2 className="text-xl font-bold">Search Employee Data:</h2>
          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Employee Name
            </label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={empdata}
              onChange={(e, v) => setRowNamedata(v)}
              getOptionLabel={(rows) => rows.emp_name || ""}
              renderInput={(params) => (
                <TextField {...params} label="Search Emp Name" size="small" />
              )}
            />
          </div>
          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Employee Code
            </label>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={empdata}
              onChange={(e, v) => setRowdata(v)}
              getOptionLabel={(rows) => rows.emp_code || ""}
              renderInput={(params) => (
                <TextField {...params} label="Search Emp Code" size="small" />
              )}
            />
          </div>
          <div className="mt-2 mb-5">
            <Divider />
          </div>
        </div>
        <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <div className="mb-4">

            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_name">
              Employee Name
            </label>
            <input
              type="text"
              name="employee_name"
              required
              value={formData.employee_name}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Name"
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_name">
              Employee Code
            </label>
            <input
              type="text"
              name="employee_name"
              required
              value={formData.employee_code}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Name"
            />
          </div>


          {/* <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Employee Code
            </label>
            <input
              type="text"
              name="employee_code"
              value={formData.employee_code}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Code"
            />
          </div> */}

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Section
            </label>
            <input
              type="text"
              name="section"
              required
              value={formData.section}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Section"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              Out Time
            </label>
            <input
              type="time"
              name="out_time"
              value={formData.out_time}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Out Time"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_code">
              In Time
            </label>
            <input
              type="time"
              name="in_time"
              value={formData.in_time}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="In Time"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="employee_mobile_no">
              Employee Mobile No
            </label>
            <input
              type="text"
              name="employee_mobile_no"
              required
              value={formData.employee_mobile_no}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Employee Mobile No"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="reason">
              Reason
            </label>
            <input
              type="text"
              name="reason"
              required
              value={formData.reason}
              onChange={handleChange}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Reason"
            />
          </div>

          {/* Add more fields */}
          <div className="mb-4">
            <div className="flex justify-between">
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="whatsapp_no">
                WhatsApp Number
              </label>
              {!isText ? (
                <button
                  type="button"
                  onClick={() => changeToText()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-semibold px-3 mb-2 rounded-md focus:outline-none focus:shadow-outline"
                >
                  Add Text
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => changeToText()}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-semibold px-3 mb-2 rounded-md focus:outline-none focus:shadow-outline"
                >
                  Select
                </button>
              )}

            </div>
            {isText ? (
              <>
                <input
                  type="text"
                  required
                  value={selectedValue}
                  onChange={handleWhatsAppChange}
                  className="w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  placeholder="Enter WhatsApp Number"
                />
              </>
            ) : (
              <>
                <select
                  value={selectedValue}
                  onChange={handleWhatsAppChange}
                  className="appearance-none block w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option value=""></option>
                  {whatsappdata && whatsappdata.map((record, index) => (
                    <option key={index} value={record.number}>
                      {record.number} - {record.name}
                    </option>
                  ))}
                </select>
              </>
            )}


          </div >

          {/* Submit button */}
          <div className="flex items-center justify-center">
            {/* <div
              onClick={handleEditOpen}
              className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Print
            </div> */}
            <div
              onClick={handleReset}
              className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Reset
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mx-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default VisitorForm;
