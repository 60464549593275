import { useState, useRef, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useReactToPrint } from "react-to-print";
import { TableContainer, Table, TableBody, TableRow, TableCell } from '@mui/material';
import QRCode from 'qrcode.react';


function PrintForm({ onClick, visitorData }) {
    const [showform, setShowform] = useState(false);
    const componentRef = useRef();
    const [empname, setEmpName] = useState("");
    const [empcode, setEmpCode] = useState("");
    const [section, setSection] = useState("");
    const [outtime, setOuttime] = useState("");
    const [intime, setIntime] = useState("");
    const [mobile, setMobile] = useState("");
    const [reason, setReason] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [path, setPath] = useState("");
    const [image, setImage] = useState("");
    const [response, setReponse] = useState("");
    const [responseReason, setReponseReason] = useState("");

    const [qdata, setQdata] = useState("");

    useEffect(() => {
        setShowform(true);
        setEmpName(visitorData.employee_name);
        setEmpCode(visitorData.employee_code);
        setSection(visitorData.section);
        setOuttime(visitorData.out_time);
        setIntime(visitorData.in_time);
        setMobile(visitorData.employee_mobile_no);
        setReason(visitorData.reason);
        setReponse(visitorData.response);
        setReponseReason(visitorData.response_reason);
        setWhatsapp(visitorData.whatsapp_no);

        let path = 'https://deeps.blog/img/post/' + visitorData.photo_path;
        setImage(path);
        gotowhatsapp();
    }, []);


    function checkDate(data) {
        const date = new Date(data);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            // timeZoneName: 'short'
        };

        const humanReadableDate = date.toLocaleString('en-US', options);
        return humanReadableDate;
    }

    function checkDate(data) {
        const date = new Date(data);

        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            // timeZoneName: 'short'
        };

        const humanReadableDate = date.toLocaleString('en-US', options);
        return humanReadableDate;
    }


    function gotowhatsapp() {
        var employee_name = visitorData.employee_name;
        var employee_code = visitorData.employee_code;
        var section = visitorData.section;
        var out_time = visitorData.out_time;
        var in_time = visitorData.in_time;
        var employee_mobile_no = visitorData.employee_mobile_no;
        var response = visitorData.response;
        var whatsapp_no = visitorData.whatsapp_no;
        var responseReason = visitorData.response_reason;
        var resUrl = "https://gatepass.deeps.blog/#/workmen/" + visitorData.id;

        // Construct the message with line breaks using %0a
        var message = "WhatsApp Number: " + whatsapp_no +
            "\n" + "Employee Name: " + employee_name +
            "\n" + "Employee ID: " + employee_code +
            "\n" + "Company Address: " + section +
            "\n" + "Visitor Mobile: " + out_time +
            "\n" + "To Meet Whom: " + in_time +
            "\n" + "Employee Mobile No: " + employee_mobile_no +
            "\n" + "Response: " + response +
            "\n" + "Response Reason: " + responseReason +
            "\n" + "Response Link: " + resUrl;

        // Construct the WhatsApp URL
        var url = message;

        // Set the QR code data
        setQdata(url);

    }

    const printRecord = () => {
        console.log("Print record..");
        handlePrint();
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {showform && (
                <>
                    <div>
                        <IconButton
                            style={{ position: "absolute", top: "0", left: "10" }}
                            onClick={printRecord}
                        >
                            <LocalPrintshopIcon
                                style={{ fontSize: "30px", color: "darkgreen" }}
                                className="cursor-pointer"
                            />
                        </IconButton>
                        <IconButton
                            style={{ position: "absolute", top: "0", right: "0" }}
                            onClick={onClick}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Box sx={{ ml: 3 }} />
                        <div ref={componentRef} style={{ marginLeft: "20px", marginRight: "20px", marginTop: "20px" }}>
                            <span>

                                <div className="flex items-center justify-center mb-2">
                                    {/* Logo */}
                                    <img
                                        src="/logo.png"
                                        alt="Logo"
                                        className="h-16"
                                    />
                                    <div>
                                        <h4 className="register-heading ml-4 text-lg">W.C.L., C.W.S., TADALI </h4>
                                        <h1 className="register-heading ml-4 text-lg">WORKMEN GATE PASS </h1>
                                    </div>
                                </div>
                                <Box sx={{ ml: 1 }} />
                                <div className="flex items-center justify-center">
                                    <div>
                                        <TableContainer>
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="left" size="small">
                                                            <Typography variant="subtitle2">
                                                                {/* {Object.keys(visitorData).map((key) => (
                                                                    <li key={key}>
                                                                        <strong>{key.replace('_', ' ')}:</strong> {visitorData[key]}
                                                                    </li>
                                                                ))} */}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Employee Name:</strong> {empname}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Employee Code:</strong> {empcode}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Section:</strong> {section}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>In Time:</strong> {intime}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Out Time:</strong> {outtime}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Date:</strong> {checkDate(Date.now())}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Employee Mobile No:</strong> {mobile}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Reason:</strong> {reason}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>WhatsApp:</strong> {whatsapp}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Response:</strong> {response}
                                                            </Typography>
                                                            <Typography variant="subtitle2">
                                                                <strong>Response Reason:</strong> {responseReason}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    {/* <div className="image-upload-container mb-10" style={{ maxWidth: "20%" }}>
                                        <div className="box-decoration">
                                            <label htmlFor="image-upload-input" className="image-upload-label">
                                            </label>
                                            <div style={{ cursor: "pointer" }}>
                                                {image ? (
                                                    <img src={image} alt="upload image" className="img-display-after" />
                                                ) : (
                                                    <img src="./photo.png" alt="upload image" className="img-display-before" />
                                                )}
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="ml-32 mt-12">
                                        {/* <QRCode value={JSON.stringify(qdata)} /> */}
                                        <QRCode value={qdata} />
                                    </div>
                                </div>
                                <div className="flex justify-between mx-10 mt-10">
                                    <h2>Department Head Sign</h2>
                                    <h2>Sec. Incharge / Foreman </h2>
                                    <h2>Workmen Sign</h2>
                                </div>
                                <Box sx={{ m: 1 }} />
                            </span>
                        </div>
                        <div className="flex justify-end">
                            <div
                                onClick={printRecord}
                            >
                                <div className="bg-transparent cursor-pointer hover:bg-green-500 text-green-700 font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded">
                                    Print
                                </div>
                            </div>
                            <div
                                onClick={onClick}
                                className="ml-5"
                            >
                                <div className="bg-transparent cursor-pointer hover:bg-orange-500 text-orange-700 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded">
                                    Close
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PrintForm;
